import React from "react";
import "./app_button.css";

/**
 * @param {object} props
 * @param {string} [props.id]
 * @param {string} [props.href]
 * @param {string} [props.rel]
 * @param {string} [props.target]
 * @param {boolean} [props.asLink]
 * @param {"submit"|"reset"|"primary"|undefined} [props.type]
 * @param {boolean} props.disabled
 * @param {import("react").MouseEventHandler} [props.onClick]
 * @param {"submit"|"create-campaign"|"download-qr"|"skip"|"create"|"join"|"landing"|undefined} [props.uniqueModifier]
 * @param {string|undefined} [props.title]
 * @param {"secondary"|"faded"|"outline"|"paypal"|"link-alike"|undefined} [props.variant]
 * @param {boolean} [props.fullWidth]
 * @param {boolean} [props.almostASquare]
 * @param {boolean} [props.smaller]
 * @param {React.ReactNode|undefined} [props.leftIcon]
 * @param {React.ReactNode|undefined} [props.rightIcon]
 * @param {string|undefined} props.title
 * @param {boolean} [props.isLoading]
 * @param {boolean} [props.disabled]
 * @param {string} [props.className]
 * @param {React.ReactNode} [props.children]
 * @param {...any} [restProps] - Any other applicable props: disabled, title, etc.
 * @returns {JSX.Element}
 */

function AppButton({
  asLink,
  type,
  isLoading,
  disabled,
  children,
  variant,
  fullWidth,
  almostASquare,
  smaller,
  uniqueModifier,
  className,
  leftIcon: LeftIconComponent,
  rightIcon: RightIconComponent,
  ...restProps
}) {
  const variantString = variant ? " button--" + variant : "";
  const uniqueModifierString = uniqueModifier
    ? " button--" + uniqueModifier
    : "";
  const fullWidthString = fullWidth ? " button--full-width" : "";
  const almostASquareString = almostASquare ? " button--almost-a-square" : "";
  const smallerString = smaller ? " button--smaller" : "";
  const classString = className ? " " + className : "";
  const disabledClassString = asLink && disabled ? " button--disabled" : "";
  const Tag = asLink ? "a" : "button";
  const typeString = type ? type : "button";

  // Notice that it is either <a> or <button>.
  //
  // By default, it is a <button> and it is used in most of the cases.
  // And we also need to clarify button type: "button" is default and "submit" can be passed as type="submit" prop
  // :disabled attribute works as expected.
  //
  // If asLink prop passed we change tag to <a>.
  // href="foobarbaz" is expected in ...restProps. target or rel can also be there.
  // :disabled attribute doesn't exist on <a>, so button--disabled class used instead. Also, href can be empty in this case.
  return (
    <Tag
      className={
        "button" +
        variantString +
        uniqueModifierString +
        fullWidthString +
        almostASquareString +
        smallerString +
        classString +
        disabledClassString
      }
      type={!asLink ? typeString : ""}
      disabled={!asLink && (disabled || isLoading)}
      {...restProps}
    >
      {LeftIconComponent && <LeftIconComponent className="button__icon" />}
      {children}
      {RightIconComponent && <RightIconComponent className="button__icon" />}
    </Tag>
  );
}

export default AppButton;
