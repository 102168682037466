import React, { FC, useCallback, useState } from "react";
import "./business.css";
import LogoPlaceholder from "../../assets/placeholders/logo-placholder.jpg";
import { ReactComponent as DownloadIcon } from "../../assets/symbols/download.svg";
import { ReactComponent as QrSmallIcon } from "../../assets/symbols/qr-small.svg";
import { ReactComponent as CopyIcon } from "../../assets/symbols/copy.svg";
import AppButton from "../AppButton/app_button";
import copy from "copy-to-clipboard";
import { notification } from "antd";
import { getLoyaltyProgramShortUrl } from "../../utils/utils";

type Props = {
  uuid: string;
  isSelected: boolean;
  onClick: () => void;
  brandName: string;
  logo: string;
  industry: string;
  address: string;
  description: string;
  selectHandler: () => void;
  editHandler: () => void;
  qrHandler: () => void;
  posterHandler: () => void;
  socialHandler: () => void;
};

const Business: FC<Props> = ({
  uuid,
  isSelected,
  onClick,
  brandName,
  logo,
  industry,
  description,
  selectHandler,
  editHandler,
  qrHandler,
  socialHandler,
}) => {
  const [isLoadingShortUrl, setIsLoadingShortUrl] = useState(false);
  const [shortUrl, setShortUrl] = useState<string>();

  const copyShortUrl = useCallback(async () => {
    if (!shortUrl) {
      try {
        setIsLoadingShortUrl(true);

        const url = await getLoyaltyProgramShortUrl(uuid);
        setShortUrl(url);

        copy(url);

        // TODO: Update in business object
      } finally {
        setIsLoadingShortUrl(false);
      }
    } else {
      copy(shortUrl);
    }

    notification.success({
      duration: 5,
      message: "Link to the Loyalty Program has been copied to your clipboard.",
    });
  }, [shortUrl, uuid]);

  return (
    <div
      onClick={onClick}
      className={`business${isSelected ? " business--selected" : ""}`}
    >
      {/* <div className="business__corner-buttons">
        <a
          href="javascript:void(0)"
          onClick={() => {
            copy(qrCodeUrl);
            notification.success({
              duration: 5,
              message:
                "URL to the QR code image has been copied to your clipboard.",
            });
          }}
        >
          Link to Loyalty Program
        </a>
      </div> */}
      <div className="business__head">
        {/*<a>... </a> can be changed to <div>...</div>*/}
        <a className="business__preview" href="#" onClick={editHandler}>
          <img
            className="business__logo"
            src={logo ? logo : LogoPlaceholder}
            alt=""
          />
        </a>
        <div className="business__summary">
          <h3 className="business__heading">
            {/*<a>... </a> can be removed*/}
            <a className="business__link" href="#" onClick={editHandler}>
              {brandName}
            </a>
          </h3>
          <div className="business__industry">{industry}</div>
          {/* <div className="business__location">
            <Location>{address}</Location>
          </div> */}
        </div>
      </div>
      <div className="business__description clamping clamping--5-rows">
        {description}
      </div>
      <div className="business__footer">
        <div className="business__actions">
          <AppButton
            onClick={selectHandler}
            title="Click select to make this loyalty program the active one to manage."
            className="select-loyalty-program-btn"
          >
            Select
          </AppButton>
          <AppButton
            variant="secondary"
            onClick={editHandler}
            title="Make changes to your loyalty program."
            className="edit-loyalty-program-btn"
          >
            Edit
          </AppButton>
        </div>
        {/* <div className="business__sub-heading">Generate</div> */}
        <div className="business__corner-buttons">
          <AppButton
            variant="secondary"
            onClick={qrHandler}
            leftIcon={QrSmallIcon}
            title="Download your loyalty program QR code as a PNG file."
            className="loyalty-program-qr-btn"
            fullWidth={true}
          >
            QR Code
          </AppButton>
          {/* <AppButton
            variant="secondary"
            onClick={posterHandler}
            leftIcon={DownloadIcon}
            title="Download a ready made poster for customers to scan and join your program."
            className="loyalty-program-poster-btn"
          >
            Poster
          </AppButton> */}
        </div>
        <div className="business__corner-buttons">
          <AppButton
            variant="secondary"
            isLoading={isLoadingShortUrl}
            onClick={copyShortUrl}
            leftIcon={CopyIcon}
            title="Copy membership link  to your clipboard."
            fullWidth={true}
            className="loyalty-program-qr-btn"
          >
            Link to Loyalty Program
          </AppButton>
        </div>
        <div className="business__corner-buttons">
          <AppButton
            variant="secondary"
            onClick={socialHandler}
            leftIcon={DownloadIcon}
            title="Generate a social media poster for your loyalty program."
            fullWidth={true}
            className="loyalty-program-qr-btn"
          >
            Social Media Poster
          </AppButton>
        </div>
      </div>
    </div>
  );
};

export default Business;
