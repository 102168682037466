import { Modal, notification } from "antd";
import AppButton from "../Components/AppButton/app_button";
import { DateTime } from "luxon";
import { setIsGoPremiumPopupShown } from "../actions/actions";
import { useDispatch } from "react-redux";
import { useCallback, useMemo } from "react";
import { fetchShortenedUrl } from "../services/urlShortenerService";

export function calculateDuration(
  currentDate: Date,
  startDate: Date,
  expiryDate: Date,
): string {
  console.log("Arg: ", currentDate, startDate, expiryDate);

  if (!currentDate || !expiryDate || !startDate) {
    return "0";
  }

  // If expiryDate has already passed, return 0
  if (currentDate > expiryDate) {
    return "0";
  }
  // If startDate has already passed, but expiryDate has not, return day diff between today and expiry date
  else if (currentDate >= startDate && currentDate <= expiryDate) {
    // Do +1 day here because both first and last days are included.
    // E.g. "Mar 10th" to "Mar 17th" results "8".
    return (
      Math.floor(
        DateTime.fromJSDate(expiryDate).diff(
          DateTime.now().startOf("day"),
          "days",
        ).days,
      ) + 1
    ).toString();
  }
  // Else, return ""day diff between currentDate and startDate" + "day diff between startDate and expiryDate" (e.g. "1 + 2")
  else {
    const daysToGo = Math.floor(
      DateTime.fromJSDate(startDate).diff(DateTime.now().startOf("day"), "days")
        .days,
    );

    // Do +1 day here because both first and last days are included.
    // E.g. "Mar 10th" to "Mar 17th" results "8".
    const duration =
      Math.floor(
        DateTime.fromJSDate(expiryDate).diff(
          DateTime.fromJSDate(startDate),
          "days",
        ).days,
      ) + 1;

    return `${daysToGo} + ${duration}`;
  }

  // Do +1 day here because both first and last days are included.
  // E.g. "Mar 10th" to "Mar 17th" results "8".
  // const duration =
  //   Math.floor(
  //     (expirationNormalized - startNormalized) / MILLISECONDS_IN_ONE_DAY,
  //   ) + 1;

  // return isNaN(duration) ? 0 : duration;
}

export const handleActionNotification = (
  performAction: () => void,
  message: JSX.Element | string,
  cancelLabel: JSX.Element | string,
  actionLabel: JSX.Element | string,
): void => {
  const modal = Modal.info({
    title: message,
    centered: true,
    okButtonProps: { style: { display: "none" } }, // Hide the default OK button
    content: (
      <>
        <AppButton variant="link-alike" onClick={() => modal.destroy()}>
          {cancelLabel}
        </AppButton>
        <AppButton
          onClick={() => {
            performAction();
            modal.destroy();
          }}
        >
          {actionLabel}
        </AppButton>
      </>
    ),
  });
};

export function hexToRgb(hex: string) {
  // Remove the hash character if present
  hex = hex.replace(/^#/, "");

  // Parse the hex value into separate RGB components
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return { r, g, b };
}

export function isColorCloseToWhite(
  { r, g, b }: { r: number; g: number; b: number },
  tolerance: number = 30,
) {
  // Set a tolerance level for closeness to white
  const whiteThreshold = 255 - tolerance;

  // Check if each component is close to white
  const isRedClose = r >= whiteThreshold;
  const isGreenClose = g >= whiteThreshold;
  const isBlueClose = b >= whiteThreshold;

  // Return true if all components are close to white
  return isRedClose && isGreenClose && isBlueClose;
}

/* export const handleActionNotification = (
  performAction,
  message,
  cancelLabel,
  actionLabel,
) => {
  const key = `open${Date.now()}`;

  const btn = (
    <Space>
      <Button type="link" size="small" onClick={() => notification.destroy()}>
        {cancelLabel}
      </Button>
      <Button
        type="primary"
        size="small"
        className="logout-btn-main"
        onClick={() => {
          performAction();
          notification.destroy(key);
        }}
      >
        {actionLabel}
      </Button>
    </Space>
  );

  notification.info({
    message,
    btn,
    key,
  });
};*/

export function useShowTierLimitReachedWarning(
  limitType: "active_campaign" | "program" | "location" | "user",
) {
  const dispatch = useDispatch();

  const showGoPremiumPopup = useCallback(() => {
    dispatch(setIsGoPremiumPopupShown(true));

    notification.destroy();
  }, [dispatch]);

  const objectType = useMemo(() => {
    switch (limitType) {
      case "active_campaign":
        return "active campaigns";
      case "user":
        return "users";
      case "location":
        return "locations";
      case "program":
        return "Loyalty Programs";
    }
  }, [limitType]);

  const showTierLimitReachedWarning = useCallback(() => {
    notification.warning({
      duration: 5,
      message: `Tier Limit Reached`,
      description: (
        <>
          To add more {objectType}, please upgrade your plan by clicking{" "}
          <a onClick={showGoPremiumPopup} target="_blank">
            here
          </a>
          .
        </>
      ),
    });
  }, [showGoPremiumPopup, objectType]);

  return showTierLimitReachedWarning;
}

export async function getQrCodeUrlForProgramUuid(
  programUuid: string,
  locationId: string,
): Promise<string> {
  const longUrl = `${process.env.REACT_APP_WEBAPP_URL}membership/${programUuid}/locationID=${locationId}`;

  // Shorten the full URL
  const shortUrl = await fetchShortenedUrl(longUrl);

  // Construct the QR code URL with the shortened URL
  const qrCodeUrl = `${process.env.REACT_APP_QR_CODE_URL}${shortUrl}`;

  return qrCodeUrl;
}

export async function getLoyaltyProgramShortUrl(
  programUuid: string,
): Promise<string> {
  const longUrl = `${process.env.REACT_APP_WEBAPP_URL}membership/${programUuid}`;

  // Shorten the full URL
  const shortUrl = await fetchShortenedUrl(longUrl);

  return shortUrl;
}

export async function awaitNotEmpty<T extends string>(
  getValue: () => T | undefined,
): Promise<T> {
  while (true) {
    const value = getValue();
    if (typeof value !== "undefined") {
      return value;
    }
    await new Promise((resolve) => setTimeout(resolve, 300));
  }
}
