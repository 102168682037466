import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import "./verify_name_page.css";
import { DeviceUUIDComponent } from "../../device_id";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { notification } from "antd";
import useGetPrograms from "../../Hooks/getPrograms";
// import useGetIndustries from "../../Hooks/getIndustries";
//import useGetOverview from "../../Hooks/getOverview";
// import useGetCountries from "../../Hooks/getCountries";
// import { Navigate } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import * as selectors from "../../selectors/selectors";
import * as actions from "../../actions/actions";
import { getIndustries } from "../../actions/industryActions";
import { warningWithTimeout } from "../../utils/helpers";
import axiosInstance from "../../services/axiosIntercept";
import AppInput from "../../Components/AppInput/app_input";
import Label from "../../Components/Label/label";
import Helper from "../../Components/Helper/helper";
import AppButton from "../../Components/AppButton/app_button";
import Popup from "../../Components/Popup/popup";

/**
 *
 * @param {object} props
 * @param {() => void} props.handleClick
 * @param {() => void} props.prevPage
 * @param {() => void} props.handleNewAccount
 * @returns {JSX.Element}
 */
function Verify_Name_Page({
  handleClick,
  prevPage,
  handleNewAccount,
}: {
  handleClick: () => void;
  prevPage: () => void;
  handleNewAccount: () => void;
}) {
  const dispatch = useDispatch();
  const uuid = useSelector(selectors.getAccountUuid);
  const accountName = useSelector(selectors.getAccountName);
  const selectedProgramId = useSelector(selectors.getSelectedLp);

  const getPrograms = useGetPrograms(uuid, selectedProgramId);
  // const getCountries = useGetCountries();
  // const getIndustries = useGetIndustries();

  //const getOverview = useGetOverview();
  const navigate = useNavigate();
  const location = useLocation();

  const [name, setName] = useState("");
  const [source, setSource] = useState<string>();

  // sandeep Nov 24 9pm AST commented const email = localStorage.getItem("email");
  const email = useSelector(selectors.getEmail);

  // Sandeep TODO Review again, Nov 27
  const clearQueryParamsAndNavigate = (path: string) => {
    // Create a new location object without the search (query params)
    const newLocation = {
      pathname: path,
      search: "", // Clears query params
      state: location.state,
      hash: location.hash,
    };

    // Navigate to the new location, replacing the current entry in the history stack
    navigate(newLocation, { replace: true });
  };

  useEffect(() => {
    const value = localStorage.getItem("source") || "";
    if (value) {
      setSource(value);
    }
  }, []);

  const handlever_codeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleSubmit = async (e: FormEvent) => {
    const ver_name_btn = document.getElementById("ver-name-btn-dis");
    (ver_name_btn as any).disabled = true;

    e.preventDefault();
    let deviceUUID = DeviceUUIDComponent();

    if (!name) {
      warningWithTimeout({
        message: "Please enter the name.",
      });
      (ver_name_btn as any).disabled = false;
    } else if (name.length < 2) {
      warningWithTimeout({
        message: "Name must be at least 2 characters long.",
      });
      (ver_name_btn as any).disabled = false;
    } else {
      console.log(name, deviceUUID);
      //console.log(email, otp);

      await axiosInstance
        .post(
          `${process.env.REACT_APP_API_URL}v3/business/update/userName`,
          {
            userName: name,
            email,
            referralSource: source,
          },
          {
            withCredentials: true,
            headers: {
              "api-key": process.env.REACT_APP_API_KEY,
              "Content-Type": "application/json",
            },
          },
        )
        .then((res) => {
          console.log(res.data.account);
          //Sandeep TODO: Nov 27 review this again!
          /*  Nov 27 commented out 
  if (!res.data.merchant) { notification.error({
            message: 'Authentication Failed. Please try again.',
          });
          clearQueryParamsAndNavigate('/'); // Call the function with the path you want to navigate to
          setDisplay(1); // Navigate back to Login_Page
          return;
          */

          dispatch(actions.setName(res.data.name));
          dispatch(actions.setId(res.data.id));
          dispatch(actions.setUuid(res.data.uuid));
          dispatch(actions.setProfileImage(res.data.profileImageId));
          dispatch(actions.setMerchantUserRole(res.data.merchantUserRole));
          localStorage.setItem("NewUser", String(true));

          console.log("Account:", accountName);

          if (
            !accountName ||
            accountName === "" ||
            accountName === "...no name..."
          ) {
            handleNewAccount();
          } else {
            handleClick();
          }

          dispatch(actions.setIsAuthenticated());
          notification.success({
            message: "Verification completed",
          });

          getPrograms(res.data.account.uuid);
          getIndustries();
          // getCountries();
          //getOverview(res.data.merchant.uuid);
          (ver_name_btn as any).disabled = false;
          // dispatch(actions.setName(res.data.account.name));
          // dispatch(actions.setMerchantUserRole(res.data.merchantUserRole));
          //dispatch(actions.setSuperAdmin(res.data.account.isSuperAdmin));
          // dispatch(actions.setId(res.data.account.id));
          // dispatch(actions.setUuid(res.data.account.uuid));
          //  Nov 27 dispatch({ type: "SET_EMAIL", payload: email }); //Sandeep
          // Nov 27 dispatch({ type: "SET_OTP", payload: otp }); //Sandeep
        })
        .catch((err) => {
          console.error(err);
          (ver_name_btn as any).disabled = false;
        });
    }
  };

  return (
    <Popup onCloseClick={prevPage} disableOverlayClick>
      <form className="auth auth--set-name" onSubmit={handleSubmit}>
        <div className="auth__heading">What's your name?</div>
        <div className="auth__panel">
          <Label>Display name</Label>
          <AppInput
            id="ver_code"
            name="ver_code"
            placeholder="Enter your name"
            value={name}
            onChange={handlever_codeChange}
          />
          <Helper>Must contain at least 2 characters</Helper>
        </div>
        <div className="auth__submit">
          <AppButton
            fullWidth
            uniqueModifier="submit"
            type="submit"
            id="ver-name-btn-dis"
          >
            Next Step
          </AppButton>
        </div>
      </form>
    </Popup>
  );
}

export default Verify_Name_Page;
